import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banner from '../../assets/images/cloud-private-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from '../storage/index.module.less';
import Button from '../../components/Button';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';
import { cls } from '../../utils';

export const NICE_LIST = [
  {
    title: '自然散热',
    desc: '无音无尘，无需专业机房，可运行于办公室、厂房等环境。',
  },
  {
    title: '防水设计',
    desc: 'IP65防水等级，适应潮湿、淋雨环境。',
  },

  {
    title: '宽温设计',
    desc: '可在-40°~70°宽温环境稳定运行，适应户外高低温变化环境。',
  },
  {
    title: '高可靠',
    desc: '结构采用内外加固设计，抗振动和冲击；主板三防保护，适应严苛环境。',
  },
  {
    title: '超低功耗',
    desc: '从架构到硬件均采用低功耗设计，典型功耗35W（30.72TB），支持自动动态降频，在超过30分钟（可自定义）无访问状态下自动进入低功耗模式。',
  },
  {
    title: '高可用',
    desc: 'M+N多重冗余，硬盘断电保护，系统实时监测、自动报警，支持异地容灾。',
  },
];

export default function CloudPrivatePage() {
  const [machActive, setMachActive] = useState(1);
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="土星云分布式存储服务器"
        desc="土星云分布式存储服务器是国科环宇自主研发的面向海量非结构化数据的新一代分布式存储产品。采用了基于军工、航天电子技术的金属导冷散热和低功耗设计，搭载了基于望获实时Linux系统内核的分布式存储软件。该产品具有自然散热、超低功耗、高可靠、无限扩容和易安装等特点，可满足海量的非结构化数据存储需求。"
      />
      <SectionWrap
        title="企业级分布式存储服务器"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.machContainer}>
          <div className={styles.machSwiper}>
            <div className={styles.imgViewCard}>
              <img src={`/saturn-product/de120s-iw/${machActive}.jpg`} alt="" />
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              height={80}
              loop={false}
              navigation
              modules={[Navigation]}
              style={{ padding: '0 40px' }}>
              {Array.from({ length: 4 }).map((item, i) => {
                const index = i + 1;
                let itemClassName = styles.machItem;
                if (index === machActive) {
                  itemClassName += ' ' + styles.machItemActive;
                }
                return (
                  <SwiperSlide key={index} style={{ width: 180 }}>
                    <div
                      className={itemClassName}
                      onClick={() => {
                        setMachActive(index);
                      }}>
                      <img src={`/saturn-product/de120s-iw/${index}.jpg`} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={styles.machInfo}>
            <div className={styles.machTitle}>土星云分布式存储服务器DE120S-IW（防水版）</div>
            <div className={styles.machDesc}>
              土星云分布式存储服务器DE120S-IW（防水版）是针对中小企业的分布式存储产品，基于国产ARM架构的瑞芯微RK3588平台，其具有8个2.5英寸固态硬盘的存储空间，具有高安全、高可靠、高易用性等特性，能够轻松应对中小企业的非结构化数据存储需求。
            </div>
            <div className={styles.machOperate}>
              <a
                href="/saturn-product/de120s-iw/doc.pdf"
                download="土星云分布式存储服务器DE120S-IW（防水版）产品规格书">
                <Button type="primary" style={{ marginRight: 20 }}>
                  资料下载
                </Button>
              </a>
              <Button
                type="outline"
                onClick={() => {
                  contactUsModalShow();
                }}>
                购买咨询
              </Button>
            </div>
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品特性" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          {NICE_LIST.map((item, index) => {
            return (
              <div className={styles.niceItem} key={index}>
                <img className={styles.niceIcon} src={`/images/cloud-private-nice-5.png`} alt="" />
                <div className={styles.niceTitle}>{item.title}</div>
                <div className={styles.niceDesc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </SectionWrap>
      <SectionWrap title="技术规格">
        <table className={cls(styles.normsTable, styles.type2)}>
          <colgroup>
            <col width={100} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={3} style={{ textAlign: 'center' }}>
                土星云分布式存储服务器DE120S-IW（防水版）
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>存储</td>
              <td>盘位数</td>
              <td>8盘位</td>
            </tr>
            <tr>
              <td>磁盘类型</td>
              <td>固态硬盘</td>
            </tr>
            <tr>
              <td>存储容量</td>
              <td>标配30.72TB，可定制</td>
            </tr>
            <tr>
              <td>处理器</td>
              <td>CPU</td>
              <td>RK3588(4 Cortex-A76+4 Cortex-A55)</td>
            </tr>
            <tr>
              <td>内存</td>
              <td>内存容量</td>
              <td>16GB</td>
            </tr>
            <tr>
              <td rowSpan={4}>I/O</td>
              <td>千兆以太网</td>
              <td>2 Ports 1000Base-T(防水航插)</td>
            </tr>
            <tr>
              <td>万兆以太网</td>
              <td>1 Port 10GbE(防水航插)</td>
            </tr>
            <tr>
              <td>USB 3.0</td>
              <td>/</td>
            </tr>
            <tr>
              <td>HDMI</td>
              <td>/</td>
            </tr>
            <tr>
              <td rowSpan={8}>支持软件</td>
              <td>操作系统</td>
              <td>望获操作系统</td>
            </tr>
            <tr>
              <td>系统架构</td>
              <td>分布式存储</td>
            </tr>
            <tr>
              <td>存储类型</td>
              <td>对象存储/块存储/文件存储</td>
            </tr>
            <tr>
              <td>存储协议</td>
              <td>S3/CIFS/iSCSI/NFS/glusterfs/FTP/SMB/btrfs等</td>
            </tr>
            <tr>
              <td>数据加密</td>
              <td>国密算法SM2/SM3/SM4</td>
            </tr>
            <tr>
              <td>数据冗余</td>
              <td>纠删码（M+N）</td>
            </tr>
            <tr>
              <td>管理工具</td>
              <td>WEB界面/命令行、S3工具</td>
            </tr>
            <tr>
              <td>系统功能</td>
              <td>
                故障巡检、自动修复，硬盘保护、硬盘失效分析，系统状态监测、自动报警，远程重启、断电保护，硬盘智能调度、寿命保护
              </td>
            </tr>
            <tr>
              <td>功耗</td>
              <td>典型功耗</td>
              <td>＜60W</td>
            </tr>
            <tr>
              <td>散热</td>
              <td>散热方式</td>
              <td>自然散热</td>
            </tr>
            <tr>
              <td>电源</td>
              <td>供电模式</td>
              <td>12V DC</td>
            </tr>
            <tr>
              <td rowSpan={4}>环境</td>
              <td>工作温度</td>
              <td>-40℃~+70℃</td>
            </tr>
            <tr>
              <td>工作湿度</td>
              <td>40℃@95% 相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>储存温度</td>
              <td>-40℃~+85℃</td>
            </tr>
            <tr>
              <td>储存湿度</td>
              <td>储存湿度：60℃@95% 相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>机械</td>
              <td>尺寸</td>
              <td>252 宽 *170 高 *212 深（单位：mm）</td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="私有云" />;
}
